export default [
  {
    icon: "wallet",
    text: "Fondos",
    path: "funds",
    name: "FundsView",
    meta: { permission: "HOME__WALLET_FUNDS" },
    fileName: "FundsView",
  },
  {
    icon: "archive",
    text: "Cierres diarios",
    path: "btm-daily-closures",
    name: "BTMDailyClosuresView",
    meta: { permission: "BTMS__CHARTS_READ" },
    fileName: "BTMDailyClosuresView",
  },
  {
    icon: "closings",
    text: "Contabilidad",
    path: "financial-closings",
    name: "FinancialClosingsView",
    fileName: "FinancialClosingsView",
  },
  // {
  //   icon: "chart-histogram",
  //   text: "Estadísticas",
  //   path: "btm-charts",
  //   name: "BTMChartsView",
  //   meta: { permission: "BTMS__CHARTS_READ" },
  //   fileName: "BTMChartsView",
  // },
  {
    icon: "gold",
    text: "UTXOs",
    path: "utxos",
    name: "UTXOsView",
    meta: { permission: "CMS_GET_UTXOS" },
    fileName: "UTXOsView",
  },
  {
    icon: "bill",
    text: "Reportes PLD",
    path: "pld",
    name: "PldView",
    // meta: { permission: "PLD_REPORT_DOWNLOAD" },
    fileName: "PldView",
  },
  {
    icon: "user-tie",
    text: "Operadores",
    path: "operators",
    name: "OperatorsView",
    meta: { permission: "OPERATORS_GET_METHOD" },
    fileName: "OperatorsView",
  },
  {
    icon: "user-role",
    text: "Roles de operador",
    path: "roles",
    name: "RolesView",
    meta: { permission: "OPERATOR_ROLES_GET_METHOD" },
    fileName: "RolesView",
  },
  {
    icon: "file-text",
    text: "Legal",
    path: "legal",
    name: "LegalView",
    meta: { permission: "BTM_TERMS_GET_METHOD" },
    fileName: "LegalView",
  },
]